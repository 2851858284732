import React from 'react'
import { inject, observer } from 'mobx-react'
import { range } from 'lodash'

@inject('global')
@observer
export default class OppositeWordsForm extends React.Component {
  handleChange = i => event => this.props.global.ui.formStore.setWord(i, event.target.value)

  render() {
    const { formStore } = this.props.global.ui
    const { words, areValid } = formStore
    return (
      <div className="words-form pb3">
        <div className="word-form-title">Parole opposte</div>
        {range(4).map(i => (
          <div key={i} className="form-row">
            <div className={`word-input word ${areValid[i * 2] ? '' : 'invalid'}`}>
              <input
                type="text"
                value={words[i * 2]}
                placeholder="parola"
                onChange={this.handleChange(i * 2)}
              />
            </div>
            <div className={`word-input opposite ${areValid[i * 2 + 1] ? '' : 'invalid'}`}>
              <input
                type="text"
                value={words[i * 2 + 1]}
                placeholder="opposto"
                onChange={this.handleChange(i * 2 + 1)}
              />
            </div>
          </div>
        ))}
      </div>
    )
  }
}
