import React from 'react'
import { inject, observer } from 'mobx-react'
import classNames from 'classnames'

@inject('global')
@observer
export default class LabelForm extends React.Component {
  state = { focus: false }
  textInput = React.createRef()
  detectFocus = () => this.setState({ focus: true })
  detectBlur = () => this.setState({ focus: false })
  triggerFocus = () => this.textInput.current.focus()
  handleLabel = e => this.props.global.ui.formStore.setLabel(e.target.value)

  render() {
    const { label } = this.props.global.ui.formStore
    const { focus } = this.state
    const initialState = !focus && label.trim() === ''
    return (
      <div className="label-form bb pa3 pb0" onClick={this.triggerFocus}>
        <label className={classNames('ml1', { 'initial-state': initialState })}>
          Salva come ...
        </label>
        <input
          className="outline-0 pa1 mt2 bn"
          type="text"
          value={label}
          ref={this.textInput}
          onChange={this.handleLabel}
          onFocus={this.detectFocus}
          onBlur={this.detectBlur}
        />
      </div>
    )
  }
}
