import React from 'react'
import { inject, observer } from 'mobx-react'
import colors from './colors'

const DIV_HEIGHT = 60
const defaultStyle = {
  width: '50%',
  height: DIV_HEIGHT,
  lineHeight: `${DIV_HEIGHT}px`,
  backgroundColor: colors.red,
  borderRadius: DIV_HEIGHT / 5,
  textAlign: 'center',
  color: 'white',
  position: 'fixed',
  left: 30,
  transition: 'bottom 0.5s',
  fontWeight: 700,
}

@inject('global')
@observer
export class Error extends React.Component {
  render() {
    const { postStatus, errorVisible } = this.props.global.ui

    const bottom = errorVisible ? 30 : -300

    return (
      <div style={{ ...defaultStyle, bottom }}>
        {postStatus === 'parse error'
          ? 'Non sono riuscito a parsare il contenuto del testo'
          : "C'è stato un errore nel server"}
      </div>
    )
  }
}
