import React from 'react'
import { portalTo } from '../lib/Portal'
import colors from '../components/colors'

class Grid extends React.Component {
  render() {
    const { x, y, side } = this.props
    return (
      <>
        {/* <g style={{ strokeWidth: 0.5, stroke: 'lightblue' }}>
          <line x1={x} y1={y + side / 6} x2={x + side} y2={y + side / 6} />
          <line x1={x} y1={y + side / 2} x2={x + side} y2={y + side / 2} />
          <line x1={x} y1={y + (side / 6) * 5} x2={x + side} y2={y + (side / 6) * 5} />
          <line x1={x + side / 6} y1={y} x2={x + side / 6} y2={y + side} />
          <line x1={x + side / 2} y1={y} x2={x + side / 2} y2={y + side} />
          <line x1={x + (side / 6) * 5} y1={y} x2={x + (side / 6) * 5} y2={y + side} />
        </g> */}
        <g style={{ strokeWidth: side / 75, stroke: 'cyan' }}>
          <line x1={x} y1={y + side / 3} x2={x + side} y2={y + side / 3} />
          <line x1={x} y1={y + (side / 3) * 2} x2={x + side} y2={y + (side / 3) * 2} />
          <line x1={x + side / 3} y1={y} x2={x + side / 3} y2={y + side} />
          <line x1={x + (side / 3) * 2} y1={y} x2={x + (side / 3) * 2} y2={y + side} />
        </g>
        <rect
          x={x}
          y={y}
          width={side}
          height={side}
          fill="none"
          style={{ strokeWidth: side / 75, stroke: 'cyan' }}
        />
      </>
    )
  }
}

export const ROLES = ['ROOT', 'nsubj', 'obj']
export const ROLES_MAP = { Soggetto: 'nsubj', Oggetto: 'obj', Root: 'ROOT' }

class UnportaledRole extends React.Component {
  render() {
    const { role, x, y, side } = this.props
    switch (role) {
      case 'ROOT':
        return (
          <line
            x1={x}
            y1={y + side}
            x2={x + side}
            y2={y}
            stroke={colors.purple}
            strokeWidth={side / 30}
          />
        )
      case 'nsubj':
        return (
          <line
            x1={x}
            y1={y + (side * 7) / 9}
            x2={x + side}
            y2={y + (side * 7) / 9}
            stroke={colors.purple}
            strokeWidth={side / 30}
          />
        )
      case 'obj':
        return (
          <line
            x1={x + (side * 2) / 9}
            y1={y}
            x2={x + (side * 2) / 9}
            y2={y + side}
            stroke={colors.purple}
            strokeWidth={side / 30}
          />
        )
      default:
        return null
    }
  }
}
const Role = portalTo('g-role')(UnportaledRole)

export const LegendRole = ({ x, y, side, role }) => (
  <>
    <Grid {...{ x, y, side }} />
    <UnportaledRole {...{ x, y, side, role }} />
  </>
)

class UnportaledQuestionMark extends React.Component {
  render() {
    const { x, y, side, question } = this.props
    return (
      question && (
        <text
          x={x}
          y={y}
          style={{ fill: 'black', fontWeight: 'bold' }}
          alignmentBaseline="middle"
          textAnchor="middle"
          fontSize={side / 6}
          fontStyle="italic"
        >
          ?
        </text>
      )
    )
  }
}
export const QuestionMark = portalTo('g-marks')(UnportaledQuestionMark)
export const LegendQuestionMark = ({ x, y, side }) => (
  <>
    <Grid {...{ x, y, side }} />
    <UnportaledQuestionMark side={side} x={x + (side / 6) * 5} y={y + (side / 6) * 5} question />
  </>
)

class UnportaledExclamationMark extends React.Component {
  render() {
    const { x, y, side, exclamation } = this.props
    return (
      exclamation && (
        <text
          x={x}
          y={y}
          style={{ fill: 'black', fontWeight: 'bold' }}
          alignmentBaseline="middle"
          textAnchor="middle"
          fontSize={side / 6}
          fontStyle="italic"
        >
          !
        </text>
      )
    )
  }
}
export const ExclamationMark = portalTo('g-marks')(UnportaledExclamationMark)
export const LegendExclamationMark = ({ x, y, side }) => (
  <>
    <Grid {...{ x, y, side }} />
    <UnportaledExclamationMark
      side={side}
      x={x + (side / 6) * 5}
      y={y + (side / 6) * 5}
      exclamation
    />
  </>
)

export class UnportaledLink extends React.Component {
  render() {
    const { x, y, side } = this.props
    return (
      <line
        x1={x}
        y1={y + side / 1.5}
        x2={x + side}
        y2={y + side / 1.5}
        stroke="yellow"
        strokeWidth={side / 12}
      />
    )
  }
}
const Link = portalTo('g-linked')(UnportaledLink)
export const LegendLink = ({ x, y, side }) => (
  <>
    <Grid {...{ x, y, side }} />
    <Grid {...{ y, side }} x={x + side} />
    <UnportaledLink {...{ x, y, side }} />
    <UnportaledLink {...{ y, side }} x={x + side} />
  </>
)

const DisplayNumber = ({ x, y, side, display }) => (
  <text
    x={x + side / 4}
    y={y + side / 4}
    style={{ fill: 'blue', fontWeight: 'bold', opacity: 0.5 }}
    alignmentBaseline="middle"
    textAnchor="middle"
    fontSize={side / 3}
  >
    {display}
  </text>
)

export const inscribedInSquare = WrappedComponent => {
  return class extends React.Component {
    render() {
      const {
        x,
        y,
        side,
        role,
        exclamation = false,
        question = false,
        grid = false,
        display = null,
        opposite,
        linked = false,
      } = this.props
      return (
        <g>
          {grid && <Grid {...{ x, y, side }} />}
          {linked && <Link {...{ x, y, side }} />}
          <WrappedComponent {...{ x, y, side, opposite, exclamation, question }} />
          <Role {...{ x, y, side, role }} />
          {display && <DisplayNumber {...{ x, y, side, display }} />}
        </g>
      )
    }
  }
}
