import React from 'react'
import ReactDOM from 'react-dom'

export const Portal = ({ children, nodeId }) => {
  const node = document.getElementById(nodeId)
  return node ? ReactDOM.createPortal(children, node) : null
}
export const portalTo = id => WrappedComponent => {
  return class extends React.Component {
    render() {
      return (
        <Portal nodeId={id}>
          <WrappedComponent {...this.props} />
        </Portal>
      )
    }
  }
}
