import { types as t } from 'mobx-state-tree'
import { DataStore, dataStore } from './dataStore'
import { UIStore, uiStore } from './uiStore'

const Global = t.model('global', {
  data: DataStore,
  ui: UIStore,
})

export const global = Global.create({
  data: dataStore,
  ui: uiStore,
})
