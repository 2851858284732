import React from 'react'
import { range } from 'lodash'
import { inscribedInSquare, QuestionMark, ExclamationMark } from '../decorators/inscribedInSquare'

@inscribedInSquare
class Tile1 extends React.Component {
  render() {
    const { x, y, side, exclamation, question } = this.props
    return (
      <>
        <circle cx={x} cy={y} r={side / 24} fill="black" />
        <circle cx={x + side} cy={y + side} r={side / 24} fill="black" />
        <line x1={x} y1={y} x2={x + side} y2={y + side} stroke="black" strokeWidth={side / 12} />
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile2 extends React.Component {
  render() {
    const { x, y, side, exclamation, question } = this.props
    return (
      <>
        <circle cx={x} cy={y + side / 2} r={side / 24} fill="black" />
        <circle cx={x + side} cy={y + side / 2} r={side / 24} fill="black" />
        <line
          x1={x}
          y1={y + side / 2}
          x2={x + side}
          y2={y + side / 2}
          stroke="black"
          strokeWidth={side / 12}
        />
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile3 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        <circle cx={x} cy={y} r={side / 24} fill="black" />
        <circle cx={x + side} cy={y} r={side / 24} fill="black" />
        <line x1={x} y1={y} x2={x + side} y2={y} stroke="black" strokeWidth={side / 12} />

        <circle cx={x} cy={y + side} r={side / 24} fill="black" />
        <circle cx={x + side} cy={y + side} r={side / 24} fill="black" />
        <line
          x1={x}
          y1={y + side}
          x2={x + side}
          y2={y + side}
          stroke="black"
          strokeWidth={side / 12}
        />
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile4 extends React.Component {
  render() {
    const { x, y, side, exclamation, question } = this.props
    return (
      <>
        <circle cx={x} cy={y + side / 2} r={side / 24} fill="black" />
        <circle cx={x + side} cy={y + side / 2} r={side / 24} fill="black" />
        <line
          x1={x}
          y1={y + side / 2}
          x2={x + side}
          y2={y + side / 2}
          stroke="black"
          strokeWidth={side / 12}
        />

        <circle cx={x + side / 2} cy={y} r={side / 24} fill="black" />
        <circle cx={x + side / 2} cy={y + side} r={side / 24} fill="black" />
        <line
          x1={x + side / 2}
          y1={y}
          x2={x + side / 2}
          y2={y + side}
          stroke="black"
          strokeWidth={side / 12}
        />
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 24}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 24} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile5 extends React.Component {
  render() {
    const { x, y, side, exclamation, question } = this.props
    return (
      <>
        <circle cx={x + side / 2} cy={y + side / 2} r={side / 24} fill="black" />
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile6 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        <circle cx={x + side / 6} cy={y + side / 6} r={side / 24} fill="black" />
        <circle cx={x + side / 2} cy={y + side / 6} r={side / 24} fill="black" />
        <circle cx={x + side - side / 6} cy={y + side / 6} r={side / 24} fill="black" />

        <circle cx={x + side / 6} cy={y + side / 2} r={side / 24} fill="black" />
        <circle cx={x + side / 2} cy={y + side / 2} r={side / 24} fill="black" />
        <circle cx={x + side - side / 6} cy={y + side / 2} r={side / 24} fill="black" />

        <circle cx={x + side / 6} cy={y + side - side / 6} r={side / 24} fill="black" />
        <circle cx={x + side / 2} cy={y + side - side / 6} r={side / 24} fill="black" />
        <circle cx={x + side - side / 6} cy={y + side - side / 6} r={side / 24} fill="black" />

        {exclamation && (
          <ExclamationMark x={x + side / 1.5} y={y + side / 1.1} {...{ side, exclamation }} />
        )}
        {question && <QuestionMark x={x + side / 3} y={y + side / 1.1} {...{ side, question }} />}
      </>
    )
  }
}

@inscribedInSquare
class Tile7 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        <path
          d={`
        M${x}, ${y} 
        L${x}, ${y + side} 
        L${x + side}, ${y + side} 
        z
      `}
          style={{ strokeLinejoin: 'round', strokeLinecap: 'round', strokeWidth: side / 12 }}
          stroke="black"
        />
        {exclamation && (
          <ExclamationMark x={x + side / 1.2} y={y + side / 2} {...{ side, exclamation }} />
        )}
        {question && <QuestionMark x={x + side / 1.5} y={y + side / 2} {...{ side, question }} />}
      </>
    )
  }
}

@inscribedInSquare
class Tile8 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        <circle cx={x + side / 2} cy={y + side / 6} r={side / 24} fill="black" />
        <circle cx={x + side / 2} cy={y + side / 2} r={side / 24} fill="black" />
        <circle cx={x + side / 2} cy={y + (side * 5) / 6} r={side / 24} fill="black" />
        {exclamation && (
          <ExclamationMark x={x + side / 1.5} y={y + side / 1.1} {...{ side, exclamation }} />
        )}
        {question && <QuestionMark x={x + side / 3} y={y + side / 1.1} {...{ side, question }} />}
      </>
    )
  }
}

@inscribedInSquare
class Tile9 extends React.Component {
  render() {
    const { x, y, side, exclamation, question } = this.props
    return (
      <>
        <circle cx={x} cy={y + side} r={side / 24} fill="black" />
        <circle cx={x + side} cy={y} r={side / 24} fill="black" />
        <line x1={x} y1={y + side} x2={x + side} y2={y} stroke="black" strokeWidth={side / 12} />
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile10 extends React.Component {
  render() {
    const { x, y, side, exclamation, question } = this.props
    return (
      <>
        <circle cx={x} cy={y + side / 2} r={side / 24} fill="black" />
        <circle cx={x + side / 2} cy={y + side / 2} r={side / 24} fill="black" />
        <circle cx={x + side - side / 6} cy={y + side / 2} r={side / 24} fill="black" />
        <line
          x1={x}
          y1={y + side / 2}
          x2={x + side / 2}
          y2={y + side / 2}
          stroke="black"
          strokeWidth={side / 12}
        />
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile11 extends React.Component {
  render() {
    const { x, y, side, exclamation, question } = this.props
    return (
      <>
        <circle cx={x} cy={y + side} r={side / 24} fill="black" />
        <circle cx={x + (side * 2) / 3} cy={y + side} r={side / 24} fill="black" />
        <line
          x1={x}
          y1={y + side}
          x2={x + (side * 2) / 3}
          y2={y + side}
          stroke="black"
          strokeWidth={side / 12}
        />
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile12 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        <circle cx={x} cy={y} r={side / 24} fill="black" />
        <circle cx={x} cy={y + side} r={side / 24} fill="black" />
        <circle cx={x + (side * 5) / 6} cy={y + side / 2} r={side / 24} fill="black" />
        <line
          x1={x}
          y1={y + side / 2}
          x2={x + (side * 5) / 6}
          y2={y + side / 2}
          stroke="black"
          strokeWidth={side / 12}
        />
        <line x1={x} y1={y} x2={x} y2={y + side} stroke="black" strokeWidth={side / 12} />
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile13 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        {range(9).map(i => (
          <line
            key={i}
            x1={x}
            y1={y + (i * side) / 12}
            x2={x + side / 2}
            y2={y + (i * side) / 12}
            stroke="black"
            strokeWidth={side / 48}
          />
        ))}
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile14 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        <path
          d={`
        M${x}, ${y} 
        L${x}, ${y + side} 
        L${x + side}, ${y} 
        z
      `}
          style={{ strokeLinejoin: 'round', strokeLinecap: 'round', strokeWidth: side / 12 }}
          stroke="black"
        />
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile15 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        <circle cx={x + side} cy={y} r={side / 24} fill="black" />
        <circle cx={x + side / 3} cy={y} r={side / 24} fill="black" />
        <circle cx={x + side / 3} cy={y + side} r={side / 24} fill="black" />
        <line
          x1={x + side / 3}
          y1={y}
          x2={x + side}
          y2={y}
          stroke="black"
          strokeWidth={side / 12}
        />
        <line
          x1={x + side}
          y1={y}
          x2={x + side / 3}
          y2={y + side}
          stroke="black"
          strokeWidth={side / 12}
        />
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile16 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        {range(13).map(i => (
          <line
            key={i}
            x1={x}
            y1={y + (i * side) / 12}
            x2={x + side}
            y2={y + (i * side) / 12}
            stroke="black"
            strokeWidth={side / 48}
          />
        ))}
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile17 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        <circle cx={x} cy={y + side} r={side / 24} fill="black" />
        <circle cx={x + side} cy={y} r={side / 24} fill="black" />
        <circle cx={x + (side * 2) / 3} cy={y} r={side / 24} fill="black" />
        <circle cx={x} cy={y + (side * 2) / 3} r={side / 24} fill="black" />
        <circle cx={x + side} cy={y + side / 3} r={side / 24} fill="black" />
        <circle cx={x + side / 3} cy={y + side} r={side / 24} fill="black" />
        <line x1={x} y1={y + side} x2={x + side} y2={y} stroke="black" strokeWidth={side / 12} />
        <line
          x1={x + side / 3}
          y1={y + side}
          x2={x + side}
          y2={y + side / 3}
          stroke="black"
          strokeWidth={side / 12}
        />
        <line
          x1={x}
          y1={y + (side / 3) * 2}
          x2={x + (side / 3) * 2}
          y2={y}
          stroke="black"
          strokeWidth={side / 12}
        />
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile18 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        <line
          x1={x + side / 2}
          y1={y}
          x2={x + side / 2}
          y2={y + side}
          stroke="black"
          strokeWidth={side / 48}
        />
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile19 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        {range(13).map(i => (
          <line
            key={i}
            x1={x}
            y1={y + (i * side) / 12}
            x2={x + (i * side) / 12}
            y2={y}
            stroke="black"
            strokeWidth={side / 48}
          />
        ))}
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile20 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        <circle cx={x + side / 2} cy={y + (side * 2) / 3} r={side / 24} fill="black" />
        <circle cx={x + side} cy={y + side} r={side / 24} fill="black" />
        <circle cx={x + side} cy={y} r={side / 24} fill="black" />
        <line
          x1={x + side / 2}
          y1={y + (side * 2) / 3}
          x2={x + side}
          y2={y + side}
          stroke="black"
          strokeWidth={side / 12}
        />
        <line
          x1={x + side}
          y1={y}
          x2={x + side}
          y2={y + side}
          stroke="black"
          strokeWidth={side / 12}
        />
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 6 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark
            x={x + side / 1.5 - side / 6 - side / 12}
            y={y + side / 1.1}
            {...{ side, question }}
          />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile21 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        <circle cx={x + side / 6} cy={y} r={side / 24} fill="black" />
        <circle cx={x + side / 6} cy={y + side} r={side / 24} fill="black" />
        <circle cx={x + side / 2} cy={y + side / 3} r={side / 24} fill="black" />
        <line
          x1={x + side / 6}
          y1={y}
          x2={x + side / 6}
          y2={y + side}
          stroke="black"
          strokeWidth={side / 12}
        />
        <line
          x1={x + side / 6}
          y1={y}
          x2={x + side / 2}
          y2={y + side / 3}
          stroke="black"
          strokeWidth={side / 12}
        />
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile22 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        <circle cx={x} cy={y + side / 2} r={side / 24} fill="black" />
        <circle cx={x + (side * 5) / 6} cy={y + side / 2} r={side / 24} fill="black" />
        <circle cx={x + (side * 5) / 6} cy={y + side} r={side / 24} fill="black" />
        <circle cx={x + (side * 4) / 6} cy={y + side / 6} r={side / 24} fill="black" />
        <line
          x1={x}
          y1={y + side / 2}
          x2={x + (side * 5) / 6}
          y2={y + side / 2}
          stroke="black"
          strokeWidth={side / 12}
        />
        <line
          x1={x + (side * 5) / 6}
          y1={y + side / 2}
          x2={x + (side * 5) / 6}
          y2={y + side}
          stroke="black"
          strokeWidth={side / 12}
        />
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile23 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        <circle cx={x + side / 3} cy={y} r={side / 24} fill="black" />
        <circle cx={x + side} cy={y} r={side / 24} fill="black" />
        <line
          x1={x + side / 3}
          y1={y}
          x2={x + side}
          y2={y}
          stroke="black"
          strokeWidth={side / 12}
        />
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile24 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        {range(13).map(i => (
          <line
            key={i}
            x1={x + (i * side) / 12}
            y1={y + side}
            x2={x + side}
            y2={y + (i * side) / 12}
            stroke="black"
            strokeWidth={side / 48}
          />
        ))}
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 4}
            y={y + side / 3}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 4} y={y + side / 3} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile25 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        <circle cx={x + side / 3} cy={y + side / 2} r={side / 24} fill="black" />
        <circle cx={x + (side / 3) * 2} cy={y + side / 2} r={side / 24} fill="black" />
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile26 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        <circle cx={x} cy={y + side / 3} r={side / 24} fill="black" />
        <circle cx={x} cy={y + (side / 3) * 2} r={side / 24} fill="black" />
        <circle cx={x + (side / 3) * 2} cy={y + side / 3} r={side / 24} fill="black" />
        <circle cx={x + (side / 3) * 2} cy={y + (side / 3) * 2} r={side / 24} fill="black" />

        <line
          x1={x}
          y1={y + side / 3}
          x2={x + (side * 2) / 3}
          y2={y + side / 3}
          stroke="black"
          strokeWidth={side / 12}
        />
        <line
          x1={x}
          y1={y + (side / 3) * 2}
          x2={x + (side * 2) / 3}
          y2={y + (side / 3) * 2}
          stroke="black"
          strokeWidth={side / 12}
        />
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile27 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        <circle cx={x + side / 3} cy={y + side / 3} r={side / 24} fill="black" />
        <circle cx={x + (side / 3) * 2} cy={y + (side / 3) * 2} r={side / 24} fill="black" />
        <line
          x1={x + side / 3}
          y1={y + side / 3}
          x2={x + (side * 2) / 3}
          y2={y + (side * 2) / 3}
          stroke="black"
          strokeWidth={side / 12}
        />
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile28 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        <circle cx={x} cy={y} r={side / 24} fill="black" />
        <circle cx={x + (side * 2) / 3} cy={y} r={side / 24} fill="black" />
        <circle cx={x} cy={y + side / 3} r={side / 24} fill="black" />
        <circle cx={x + (side * 2) / 3} cy={y + side / 3} r={side / 24} fill="black" />
        <circle cx={x} cy={y + (side / 3) * 2} r={side / 24} fill="black" />
        <circle cx={x + (side * 2) / 3} cy={y + (side / 3) * 2} r={side / 24} fill="black" />
        <circle cx={x} cy={y + side} r={side / 24} fill="black" />
        <circle cx={x + (side * 2) / 3} cy={y + side} r={side / 24} fill="black" />
        <line x1={x} y1={y} x2={x + (side * 2) / 3} y2={y} stroke="black" strokeWidth={side / 12} />
        <line
          x1={x}
          y1={y + side / 3}
          x2={x + (side * 2) / 3}
          y2={y + side / 3}
          stroke="black"
          strokeWidth={side / 12}
        />
        <line
          x1={x}
          y1={y + (side / 3) * 2}
          x2={x + (side * 2) / 3}
          y2={y + (side / 3) * 2}
          stroke="black"
          strokeWidth={side / 12}
        />
        <line
          x1={x}
          y1={y + side}
          x2={x + (side * 2) / 3}
          y2={y + side}
          stroke="black"
          strokeWidth={side / 12}
        />
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile29 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        <circle cx={x} cy={y} r={side / 24} fill="black" />
        <circle cx={x + side} cy={y} r={side / 24} fill="black" />
        <line x1={x} y1={y} x2={x + side} y2={y} stroke="black" strokeWidth={side / 12} />
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile30 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        <circle cx={x} cy={y + side} r={side / 24} fill="black" />
        <circle cx={x + side} cy={y + side} r={side / 24} fill="black" />
        <line
          x1={x}
          y1={y + side}
          x2={x + side}
          y2={y + side}
          stroke="black"
          strokeWidth={side / 12}
        />
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}
@inscribedInSquare
class Tile31 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        <circle cx={x} cy={y + side} r={side / 24} fill="black" />
        <circle cx={x + side} cy={y + side} r={side / 24} fill="black" />
        <circle cx={x + side / 2} cy={y + side / 2} r={side / 24} fill="black" />
        <line
          x1={x}
          y1={y + side}
          x2={x + side}
          y2={y + side}
          stroke="black"
          strokeWidth={side / 12}
        />
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile32 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        <circle cx={x} cy={y} r={side / 24} fill="black" />
        <circle cx={x + side} cy={y} r={side / 24} fill="black" />
        <circle cx={x} cy={y + side / 3} r={side / 24} fill="black" />
        <circle cx={x + side} cy={y + side / 3} r={side / 24} fill="black" />
        <circle cx={x} cy={y + (side / 3) * 2} r={side / 24} fill="black" />
        <circle cx={x + side} cy={y + (side / 3) * 2} r={side / 24} fill="black" />
        <circle cx={x} cy={y + side} r={side / 24} fill="black" />
        <circle cx={x + side} cy={y + side} r={side / 24} fill="black" />
        <line x1={x} y1={y} x2={x + side} y2={y} stroke="black" strokeWidth={side / 12} />
        <line
          x1={x}
          y1={y + side / 3}
          x2={x + side}
          y2={y + side / 3}
          stroke="black"
          strokeWidth={side / 12}
        />
        <line
          x1={x}
          y1={y + (side / 3) * 2}
          x2={x + side}
          y2={y + (side / 3) * 2}
          stroke="black"
          strokeWidth={side / 12}
        />
        <line
          x1={x}
          y1={y + side}
          x2={x + side}
          y2={y + side}
          stroke="black"
          strokeWidth={side / 12}
        />
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile33 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        <line x1={x} y1={y + side} x2={x + side} y2={y} stroke="black" strokeWidth={side / 48} />
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile34 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        <line
          x1={x}
          y1={y + side / 2}
          x2={x + side}
          y2={y + side / 2}
          stroke="black"
          strokeWidth={side / 48}
        />
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}
@inscribedInSquare
class Tile35 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        <circle cx={x} cy={y} r={side / 24} fill="black" />
        <circle cx={x + (side * 2) / 3} cy={y + (side * 2) / 3} r={side / 24} fill="black" />
        <line
          x1={x}
          y1={y}
          x2={x + (side * 2) / 3}
          y2={y + (side * 2) / 3}
          stroke="black"
          strokeWidth={side / 12}
        />
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile36 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        <circle cx={x + side} cy={y} r={side / 24} fill="black" />
        <circle cx={x + side / 3} cy={y + (side * 2) / 3} r={side / 24} fill="black" />
        <line
          x1={x + side}
          y1={y}
          x2={x + side / 3}
          y2={y + (side / 3) * 2}
          stroke="black"
          strokeWidth={side / 12}
        />
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile37 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        <circle cx={x + side / 6} cy={y + side / 2} r={side / 24} fill="black" />
        <circle cx={x + (side / 6) * 5} cy={y + side / 2} r={side / 24} fill="black" />
        <circle cx={x + side / 2} cy={y + side / 2} r={side / 24} fill="black" />
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile38 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        <circle cx={x} cy={y} r={side / 24} fill="black" />
        <circle cx={x + side} cy={y} r={side / 24} fill="black" />
        <circle cx={x} cy={y + side} r={side / 24} fill="black" />
        <circle cx={x + side / 3} cy={y + (side * 2) / 3} r={side / 24} fill="black" />
        <line x1={x} y1={y} x2={x + side} y2={y} stroke="black" strokeWidth={side / 12} />

        <line
          x1={x}
          y1={y + side}
          x2={x + side / 3}
          y2={y + (side * 2) / 3}
          stroke="black"
          strokeWidth={side / 12}
        />
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile39 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        <path
          d={`
          M${x + side / 2}, ${y + (side * 2) / 9}
          L${x + (side * 7) / 9}, ${y + side / 2}
          L${x + side / 2}, ${y + (side * 7) / 9} 
          L${x + (side * 2) / 9}, ${y + side / 2} 
          z
        `}
          style={{ strokeLinejoin: 'round', strokeLinecap: 'round', strokeWidth: side / 12 }}
          stroke="black"
        />
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile40 extends React.Component {
  render() {
    const { x, y, side, exclamation, question } = this.props
    return (
      <>
        <circle cx={x + side / 3} cy={y + side / 3} r={side / 24} fill="black" />
        <circle cx={x + side} cy={y + side / 3} r={side / 24} fill="black" />
        <circle cx={x + side / 3} cy={y + (side * 2) / 3} r={side / 24} fill="black" />
        <circle cx={x + side} cy={y + (side * 2) / 3} r={side / 24} fill="black" />

        <line
          x1={x + side / 3}
          y1={y + side / 3}
          x2={x + side}
          y2={y + side / 3}
          stroke="black"
          strokeWidth={side / 12}
        />
        <line
          x1={x + side / 3}
          y1={y + (side * 2) / 3}
          x2={x + side}
          y2={y + (side * 2) / 3}
          stroke="black"
          strokeWidth={side / 12}
        />
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile41 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        <circle cx={x + side / 3} cy={y + side / 3} r={side / 24} fill="black" />
        <circle cx={x + side / 3} cy={y + side} r={side / 24} fill="black" />
        <circle cx={x + (side / 3) * 2} cy={y} r={side / 24} fill="black" />
        <circle cx={x + (side * 2) / 3} cy={y + (side * 2) / 3} r={side / 24} fill="black" />

        <line
          x1={x + side / 3}
          y1={y + side / 3}
          x2={x + side / 3}
          y2={y + side}
          stroke="black"
          strokeWidth={side / 12}
        />
        <line
          x1={x + (side / 3) * 2}
          y1={y}
          x2={x + (side / 3) * 2}
          y2={y + (side * 2) / 3}
          stroke="black"
          strokeWidth={side / 12}
        />
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile42 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        <circle cx={x + side / 2} cy={y + side / 2} r={side / 24} fill="black" />
        <circle cx={x + side} cy={y} r={side / 24} fill="black" />
        <circle cx={x + side} cy={y + side} r={side / 24} fill="black" />
        <line
          x1={x + side}
          y1={y}
          x2={x + side}
          y2={y + side}
          stroke="black"
          strokeWidth={side / 12}
        />
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile43 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        <circle cx={x + side / 2} cy={y} r={side / 24} fill="black" />
        <circle cx={x + side} cy={y} r={side / 24} fill="black" />
        <circle cx={x + side / 2} cy={y + side / 3} r={side / 24} fill="black" />
        <circle cx={x + side} cy={y + side / 3} r={side / 24} fill="black" />
        <line
          x1={x + side / 2}
          y1={y}
          x2={x + side}
          y2={y}
          stroke="black"
          strokeWidth={side / 12}
        />
        <line
          x1={x + side / 2}
          y1={y + side / 3}
          x2={x + side}
          y2={y + side / 3}
          stroke="black"
          strokeWidth={side / 12}
        />
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile44 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        {range(7).map(i => (
          <line
            key={i}
            x1={x + (i * side) / 12}
            y1={y}
            x2={x + (i * side) / 12}
            y2={y + side / 1.5}
            stroke="black"
            strokeWidth={side / 48}
          />
        ))}
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile45 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        {range(5).map(i => (
          <line
            key={i}
            x1={x + (i * side) / 12}
            y1={y}
            x2={x + (i * side) / 12}
            y2={y + side}
            stroke="black"
            strokeWidth={side / 48}
          />
        ))}
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile46 extends React.Component {
  render() {
    const { x, y, side, exclamation, question } = this.props
    return (
      <>
        {range(5).map(i => (
          <line
            key={i}
            x1={x + side / 3 + (i * side) / 12}
            y1={y}
            x2={x + side / 3 + (i * side) / 12}
            y2={y + side}
            stroke="black"
            strokeWidth={side / 48}
          />
        ))}
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 + side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 + side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile47 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        {range(5).map(i => (
          <line
            key={i}
            x1={x + side / 1.5 + (i * side) / 12}
            y1={y}
            x2={x + side / 1.5 + (i * side) / 12}
            y2={y + side}
            stroke="black"
            strokeWidth={side / 48}
          />
        ))}
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 4}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 4} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile48 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        {range(5).map(i => (
          <line
            key={i}
            x1={x + side / 3}
            y1={y + side / 3 + (i * side) / 12}
            x2={x + side}
            y2={y + side / 3 + (i * side) / 12}
            stroke="black"
            strokeWidth={side / 48}
          />
        ))}
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile49 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        {range(5).map(i => (
          <line
            key={i}
            x1={x + side / 3 + (i * side) / 12}
            y1={y}
            x2={x + side / 3 + (i * side) / 12}
            y2={y + side}
            stroke="black"
            strokeWidth={side / 48}
          />
        ))}
        <circle cx={x} cy={y + side / 1.5} r={side / 24} fill="black" />
        <circle cx={x + side} cy={y + side / 1.5} r={side / 24} fill="black" />
        <line
          x1={x}
          y1={y + side / 1.5}
          x2={x + side}
          y2={y + side / 1.5}
          stroke="black"
          strokeWidth={side / 12}
        />
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile50 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        <circle cx={x} cy={y} r={side / 24} fill="black" />
        <circle cx={x} cy={y + side} r={side / 24} fill="black" />
        <circle cx={x + side / 2} cy={y + side / 2} r={side / 24} fill="black" />
        <line x1={x} y1={y} x2={x} y2={y + side} stroke="black" strokeWidth={side / 12} />
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile51 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        {range(5).map(i => (
          <line
            key={i}
            y1={y + side / 3 + (i * side) / 12}
            x1={x}
            y2={y + side / 3 + (i * side) / 12}
            x2={x + side}
            stroke="black"
            strokeWidth={side / 48}
          />
        ))}
        <circle cx={x + side / 3} cy={y + side / 1.5} r={side / 24} fill="black" />
        <circle cx={x + side / 1.5} cy={y + side / 3} r={side / 24} fill="black" />
        <line
          x1={x + side / 3}
          y1={y + side / 1.5}
          x2={x + side / 1.5}
          y2={y + side / 3}
          stroke="black"
          strokeWidth={side / 12}
        />
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile52 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        {range(5).map(i => (
          <line
            key={i}
            y1={y + side / 3 + (i * side) / 12}
            x1={x}
            y2={y + side / 3 + (i * side) / 12}
            x2={x + side}
            stroke="black"
            strokeWidth={side / 48}
          />
        ))}
        <circle cx={x + side / 1.5} cy={y} r={side / 24} fill="black" />
        <circle cx={x + side / 1.5} cy={y + side} r={side / 24} fill="black" />
        <line
          x1={x + side / 1.5}
          y1={y}
          x2={x + side / 1.5}
          y2={y + side}
          stroke="black"
          strokeWidth={side / 12}
        />
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile53 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        {range(3).map(i => (
          <line
            key={i}
            x1={x + (side / 12) * i}
            y1={y + side}
            x2={x + side}
            y2={y + (i * side) / 12}
            stroke="black"
            strokeWidth={side / 48}
          />
        ))}
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile54 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        <circle cx={x + side / 6} cy={y + side / 2} r={side / 24} fill="black" />
        <circle cx={x + side / 2} cy={y + side / 2} r={side / 24} fill="black" />
        <circle cx={x + side / 1.2} cy={y + side / 2} r={side / 24} fill="black" />
        <line
          x1={x}
          y1={y + side / 3}
          x2={x + side}
          y2={y + side / 3}
          stroke="black"
          strokeWidth={side / 48}
        />
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile55 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        {range(13).map(i => (
          <line
            key={i}
            x1={x + (side / 12) * i}
            y1={y}
            x2={x + (side / 12) * i}
            y2={y + side}
            stroke="black"
            strokeWidth={side / 48}
          />
        ))}
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 24}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 24} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile56 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        <line
          x1={x + (side / 12) * 4}
          y1={y}
          x2={x + (side / 12) * 4}
          y2={y + side}
          stroke="black"
          strokeWidth={side / 48}
        />
        <line
          x1={x + (side / 12) * 3}
          y1={y}
          x2={x + (side / 12) * 3}
          y2={y + side}
          stroke="black"
          strokeWidth={side / 48}
        />
        <line
          x1={x + (side / 12) * 9}
          y1={y}
          x2={x + (side / 12) * 9}
          y2={y + side}
          stroke="black"
          strokeWidth={side / 48}
        />
        <line
          x1={x + (side / 12) * 8}
          y1={y}
          x2={x + (side / 12) * 8}
          y2={y + side}
          stroke="black"
          strokeWidth={side / 48}
        />
        <circle cx={x + side / 6} cy={y + side / 1.5} r={side / 24} fill="black" />
        <circle cx={x + side / 1.2} cy={y + side / 1.5} r={side / 24} fill="black" />
        <line
          x1={x + side / 6}
          y1={y + side / 1.5}
          x2={x + side / 1.2}
          y2={y + side / 1.5}
          stroke="black"
          strokeWidth={side / 12}
        />
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 24}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile57 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        <line
          x1={x + (side / 12) * 4}
          y1={y}
          x2={x + (side / 12) * 4}
          y2={y + side}
          stroke="black"
          strokeWidth={side / 48}
        />
        <line
          x1={x + (side / 12) * 3}
          y1={y}
          x2={x + (side / 12) * 3}
          y2={y + side}
          stroke="black"
          strokeWidth={side / 48}
        />
        <line
          x1={x + (side / 12) * 9}
          y1={y}
          x2={x + (side / 12) * 9}
          y2={y + side}
          stroke="black"
          strokeWidth={side / 48}
        />
        <line
          x1={x + (side / 12) * 8}
          y1={y}
          x2={x + (side / 12) * 8}
          y2={y + side}
          stroke="black"
          strokeWidth={side / 48}
        />
        <circle cx={x + side / 6} cy={y + side / 3} r={side / 24} fill="black" />
        <circle cx={x + side / 1.2} cy={y + side / 3} r={side / 24} fill="black" />
        <line
          x1={x + side / 6}
          y1={y + side / 3}
          x2={x + side / 1.2}
          y2={y + side / 3}
          stroke="black"
          strokeWidth={side / 12}
        />
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 24}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile58 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        <line
          x1={x + (side / 12) * 4}
          y1={y}
          x2={x + (side / 12) * 4}
          y2={y + side}
          stroke="black"
          strokeWidth={side / 48}
        />
        <line
          x1={x + (side / 12) * 3}
          y1={y}
          x2={x + (side / 12) * 3}
          y2={y + side}
          stroke="black"
          strokeWidth={side / 48}
        />
        <line
          x1={x + (side / 12) * 9}
          y1={y}
          x2={x + (side / 12) * 9}
          y2={y + side}
          stroke="black"
          strokeWidth={side / 48}
        />
        <line
          x1={x + (side / 12) * 8}
          y1={y}
          x2={x + (side / 12) * 8}
          y2={y + side}
          stroke="black"
          strokeWidth={side / 48}
        />
        <circle cx={x + side / 2} cy={y + side / 2} r={side / 24} fill="black" />
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 24}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile59 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        <circle cx={x} cy={y + side} r={side / 24} fill="black" />
        <circle cx={x + side / 3} cy={y + side / 1.5} r={side / 24} fill="black" />
        <circle cx={x + side / 1.5} cy={y + side / 3} r={side / 24} fill="black" />
        <line
          x1={x}
          y1={y + side}
          x2={x + side / 3}
          y2={y + side / 1.5}
          stroke="black"
          strokeWidth={side / 12}
        />
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile60 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        <circle cx={x + side} cy={y} r={side / 24} fill="black" />
        <circle cx={x + side / 3} cy={y + side / 1.5} r={side / 24} fill="black" />
        <circle cx={x + side / 1.5} cy={y + side / 3} r={side / 24} fill="black" />
        <line
          x1={x + side}
          y1={y}
          x2={x + side / 1.5}
          y2={y + side / 3}
          stroke="black"
          strokeWidth={side / 12}
        />
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile61 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        <circle cx={x + side / 6} cy={y + side / 2} r={side / 24} fill="black" />
        <circle cx={x + side / 2} cy={y + side / 2} r={side / 24} fill="black" />
        <circle cx={x + side / 1.2} cy={y + side / 2} r={side / 24} fill="black" />
        <line
          x1={x}
          y1={y + side / 1.5}
          x2={x + side}
          y2={y + side / 1.5}
          stroke="black"
          strokeWidth={side / 48}
        />
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

@inscribedInSquare
class Tile62 extends React.Component {
  render() {
    const { x, y, side, question, exclamation } = this.props
    return (
      <>
        <circle cx={x} cy={y} r={side / 24} fill="black" />
        <circle cx={x + side} cy={y + side} r={side / 24} fill="black" />
        <circle cx={x + (side * 2) / 3} cy={y + side} r={side / 24} fill="black" />
        <circle cx={x} cy={y + side / 3} r={side / 24} fill="black" />
        <circle cx={x + side} cy={y + (side / 3) * 2} r={side / 24} fill="black" />
        <circle cx={x + side / 3} cy={y} r={side / 24} fill="black" />
        <line x1={x} y1={y} x2={x + side} y2={y + side} stroke="black" strokeWidth={side / 12} />
        <line
          x1={x + (side / 3) * 2}
          y1={y + side}
          x2={x}
          y2={y + side / 3}
          stroke="black"
          strokeWidth={side / 12}
        />
        <line
          x1={x + side}
          y1={y + (side / 3) * 2}
          x2={x + side / 3}
          y2={y}
          stroke="black"
          strokeWidth={side / 12}
        />
        {exclamation && (
          <ExclamationMark
            x={x + side / 1.2 - side / 12}
            y={y + side / 1.1}
            {...{ side, exclamation }}
          />
        )}
        {question && (
          <QuestionMark x={x + side / 1.5 - side / 12} y={y + side / 1.1} {...{ side, question }} />
        )}
      </>
    )
  }
}

export const tiles = [
  Tile1,
  Tile2,
  Tile3,
  Tile4,
  Tile5,
  Tile6,
  Tile7,
  Tile8,
  Tile9,
  Tile10,
  Tile11,
  Tile12,
  Tile13,
  Tile14,
  Tile15,
  Tile16,
  Tile17,
  Tile18,
  Tile19,
  Tile20,
  Tile21,
  Tile22,
  Tile23,
  Tile24,
  Tile25,
  Tile26,
  Tile27,
  Tile28,
  Tile29,
  Tile30,
  Tile31,
  Tile32,
  Tile33,
  Tile34,
  Tile35,
  Tile36,
  Tile37,
  Tile38,
  Tile39,
  Tile40,
  Tile41,
  Tile42,
  Tile43,
  Tile44,
  Tile45,
  Tile46,
  Tile47,
  Tile48,
  Tile49,
  Tile50,
  Tile51,
  Tile52,
  Tile53,
  Tile54,
  Tile55,
  Tile56,
  Tile57,
  Tile58,
  Tile59,
  Tile60,
  Tile61,
  Tile62,
]
