import React from 'react'
import { inject, observer } from 'mobx-react'

@inject('global')
@observer
export default class HistorySelect extends React.Component {
  state = { open: false }

  componentDidUpdate() {
    if (this.state.open) document.getElementById('history').scrollIntoView(true)
  }

  toggle = () => {
    const { open } = this.state
    const { setHistory } = this.props.global.data
    if (!open) setHistory()
    this.setState({ open: !open })
  }

  clickDatum = id => () => this.props.global.data.fetchDataset(`/status/${id}`)
  deleteDatum = id => event => {
    const { deleteFromHistory } = this.props.global.data
    if (window.confirm('Questo elemento verrà rimosso dal database, vuoi continuare?')) {
      deleteFromHistory(id)
    }
    event.stopPropagation()
  }

  render() {
    const { historyStore, currentRequest, dataset } = this.props.global.data
    const { open } = this.state
    return (
      <div id="history" className="bt bb">
        <div className="history-title pa2 pointer tc" onClick={this.toggle}>
          Storico
        </div>
        <div className={`history-content ${open ? 'open' : 'closed'}`}>
          <div className="inner-content">
            {historyStore.length === 0 && currentRequest.length === 0 && (
              <div className="history-empty-element pl3 pr3 pt3 pb1 ">
                <div className="label">Nessun elemento</div>
              </div>
            )}
            {currentRequest
              .slice()
              .sort((a, b) => b.timeRequest - a.timeRequest)
              .map(datum => (
                <div className="history-pending pl3 pr3 pt1 pb1 bb" key={datum.id}>
                  <div className="label">{datum.label || datum.id}</div>
                  <div className="time-request">
                    <div>{datum.time}</div>
                    <div>{datum.day}</div>
                  </div>
                </div>
              ))}
            {historyStore
              .slice()
              .sort((a, b) => b.timeRequest - a.timeRequest)
              .map(datum => (
                <div
                  key={datum.id}
                  className={`history-element pl3 pr3 pt1 pb1 bb pointer ${
                    dataset && dataset.id === datum.id ? 'history-element-current' : ''
                  }`}
                  onClick={this.clickDatum(datum.id)}
                >
                  <div className="label">{datum.label || datum.id}</div>
                  <div className="time-request">
                    <div>{datum.time}</div>
                    <div>{datum.day}</div>
                  </div>
                  <div className="delete-element ml2 b " onClick={this.deleteDatum(datum.id)}>
                    &times;
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    )
  }
}
