import React from 'react'
import { observer, inject } from 'mobx-react'
import { zipObject, toLower, keys, values, invert } from 'lodash'
import { tiles } from './Tile'
import { opposites } from './Opposite'
import Picture from './Picture'
import Legend from './Legend'

@inject('global')
@observer
export default class View extends React.Component {
  render() {
    const { global } = this.props
    if (!global.data.dataset) return null

    const { data, allOpposite, nonOpposite, oppositeWords } = global.data.dataset
    const relevantToTile = zipObject(nonOpposite.map(toLower), tiles)

    const oppostiteToCreateTile = zipObject(keys(oppositeWords), opposites)
    const oppositeToTile = zipObject(
      allOpposite.map(toLower),
      allOpposite.map(word => {
        if (keys(oppositeWords).includes(word)) return oppostiteToCreateTile[word](false)
        else if (values(oppositeWords).includes(word)) {
          return oppostiteToCreateTile[invert(oppositeWords)[word]](true)
        }
      }),
    )

    const wordToTile = { ...relevantToTile, ...oppositeToTile }
    const dataToShow = data.filter(r => wordToTile[r.occurance] !== undefined)

    return (
      <div className="view">
        <div className="slot">
          <div className="content">
            <Picture {...{ wordToTile, dataToShow }} />
          </div>
        </div>
        <div className="slot">
          <div className="content">
            <Legend {...{ relevantToTile, oppositeToTile }} />
          </div>
        </div>
      </div>
    )
  }
}
