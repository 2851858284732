import React from 'react'
import { inject, observer } from 'mobx-react'
import TextFileDropzone from './TextFileDropzone'
import OppositeWordsForm from './OppositeWordsForm'
import HistorySelect from './HistorySelect'
import LabelForm from './LabelForm'
import Download from './Download'

@inject('global')
@observer
export class Upload extends React.Component {
  handleSubmit = event => {
    const { postData, isPostNotAllowed } = this.props.global.ui
    // return
    event.preventDefault()
    if (isPostNotAllowed) return null
    postData()
  }

  handleFileChange = event => {
    this.props.global.ui.formStore.setTextFile(event.target.files[0])
  }

  toggleUpload = () => this.props.global.ui.toggleUploadOpen()

  render() {
    const { isPostNotAllowed, uploadOpen } = this.props.global.ui
    const { currentRequest } = this.props.global.data
    return (
      <div className={`upload ${uploadOpen ? 'upload-open' : ''}`}>
        <div className="outer shadow-4">
          <div className="inner pre">
            <TextFileDropzone />
            <LabelForm />
            <OppositeWordsForm />
            <div className="flex items-center">
              <button
                className="submit-input btt br-pill mb3 outline-0"
                type="submit"
                disabled={isPostNotAllowed || currentRequest.length !== 0}
                onClick={this.handleSubmit}
              >
                Genera
              </button>
            </div>
            <Download />
            <HistorySelect />
          </div>
        </div>
        <div className="collapse pointer" onClick={this.toggleUpload}>
          <div>&#9664;</div>
        </div>
      </div>
    )
  }
}
