import React from 'react'
import { saveAs } from 'file-saver'
import { inject, observer } from 'mobx-react'

function download(id, filename) {
  const svg = document.getElementById(id)
  if (!svg) {
    return
  }
  const canvas = document.createElement('canvas')
  const { width: svgW, height: svgH } = svg.getBBox()
  const cnvW = (canvas.width = 1920)
  const cnvH = (canvas.height = 1080)
  let offX = 0
  let offY = 0
  if (svgW / cnvW > svgH / cnvH) {
    let ratio = svgW / cnvW
    let scaledH = svgH / ratio
    offY = (cnvH - scaledH) / 2
  } else {
    let ratio = svgH / cnvH
    let scaledW = svgW / ratio
    offX = (cnvW - scaledW) / 2
  }
  const context = canvas.getContext('2d')
  context.fillStyle = 'white'
  context.fillRect(0, 0, cnvW, cnvH)
  const svgURL = new window.XMLSerializer().serializeToString(svg)
  const img = new window.Image()

  img.onload = function() {
    context.drawImage(this, 0, 0, svgW, svgH, offX, offY, cnvW - 2 * offX, cnvH - 2 * offY)
    canvas.toBlob(b => saveAs(b, filename))
  }
  img.src = `data:image/svg+xml; charset=utf8, ${encodeURIComponent(svgURL)}`
}

@inject('global')
@observer
export default class Download extends React.Component {
  downloadPicture = () => download('picture', 'picture.png')
  downloadLegend = () => download('legend', 'legend.png')

  render() {
    const { dataset } = this.props.global.data
    const disabled = !dataset
    return (
      <div className="download pt3 mb3">
        <div>
          <button
            className="download-picture btt br-pill br--left"
            disabled={disabled}
            onClick={this.downloadPicture}
          >
            Figura
          </button>
          <button
            className="download-legend btt br-pill br--right"
            disabled={disabled}
            onClick={this.downloadLegend}
          >
            Legenda
          </button>
        </div>
      </div>
    )
  }
}
