import { types as t, flow, getRoot } from 'mobx-state-tree'
import { toLower, keys, uniq, values, debounce } from 'lodash'
import ky from 'ky'
import dayjs from 'dayjs'

const GET_HISTORY_URL = `${process.env.SERVER_URL}/get_all`
const DELETE_HISTORY_URL = `${process.env.SERVER_URL}/remove`
const DEBOUNCE_TIME = 2000
const MAX_HISTORY_PINGS = 360_000 / DEBOUNCE_TIME

async function fetchData(url) {
  const rawData = await ky.get(url)
  const data = await rawData.json()

  return data
}
const Datum = t.model('datum', {
  exclamationMark: t.boolean,
  index: t.number,
  occurance: t.string,
  oppositeWord: t.maybeNull(t.string),
  questionMark: t.boolean,
  role: t.maybeNull(t.string),
})

const Dataset = t
  .model('dataStore', {
    oppositeWords: t.frozen(),
    relevantWords: t.array(t.string),
    data: t.array(Datum),
    id: '',
  })
  .views(self => ({
    get relevantWordsLower() {
      return self.relevantWords.map(toLower)
    },
    get allOpposite() {
      return [...keys(self.oppositeWords), ...values(self.oppositeWords)]
    },
    get nonOpposite() {
      return self.relevantWords.filter(w => !self.allOpposite.map(toLower).includes(toLower(w)))
    },
    get oppositeWordsOccurrences() {
      const wordsOccurance = uniq(self.data.map(datum => toLower(datum.occurance)))
      return keys(self.oppositeWords)
        .map(toLower)
        .filter(w => wordsOccurance.includes(w))
    },
    get linked() {
      const indexes = self.data.map(datum => datum.index)
      return uniq(indexes).filter(i => indexes.filter(j => i === j).length > 1)
    },
  }))

function parseRawData(rawDataset) {
  return {
    id: rawDataset['_id'],
    oppositeWords: rawDataset['opposite_words'],
    relevantWords: rawDataset['relevant_words'],
    data: rawDataset['data'].map(r => ({
      role: ['ROOT', 'nsubj', 'obj'].includes(r.role) ? r.role : null,
      index: r.index,
      occurance: r.occurance,
      exclamationMark: r.exclamation_mark,
      oppositeWord: r.opposite_word,
      questionMark: r.question_mark,
    })),
  }
}

function parseRawHistory(raw) {
  return raw['all_ids'].map(datum => ({
    id: datum['id'],
    timeRequest: datum['time_request'],
    label: datum['label'],
  }))
}

const AvailableID = t
  .model('availableID', {
    id: '',
    timeRequest: t.maybeNull(t.number),
    label: t.maybeNull(t.string),
  })
  .views(self => ({
    get time() {
      return dayjs(self.timeRequest * 1000).format('HH:mm')
    },
    get day() {
      return dayjs(self.timeRequest * 1000).format('DD/MM/YYYY')
    },
  }))

async function fetchHistory() {
  const response = await ky.get(GET_HISTORY_URL).json()
  const newHistory = parseRawHistory(response)
  return newHistory
}

export const DataStore = t
  .model('dataStore', {
    dataset: t.maybeNull(Dataset),
    historyStore: t.array(AvailableID),
    currentRequest: t.array(AvailableID),
    fetchStatus: t.enumeration('State', ['pending', 'done', 'error']),
    historyPingIterations: 0,
  })
  .actions(self => ({
    afterCreate() {
      self.setHistory()
    },
    fetchDataset: flow(function* fetchDataset(locationResult) {
      const dataUrl = `${process.env.SERVER_URL}${locationResult}`
      self.dataset = null
      self.fetchStatus = 'pending'
      try {
        const response = yield fetchData(dataUrl)
        self.dataset = parseRawData(response)
        self.fetchStatus = 'done'
      } catch (error) {
        self.fetchStatus = 'error'
      }
    }),
    setHistory: flow(function*() {
      const newHistory = yield fetchHistory()
      self.historyStore.replace(newHistory)
    }),
    pingFetchHistory: flow(function*() {
      try {
        const newHistory = yield fetchHistory()
        if (
          newHistory.length !== self.historyStore.length ||
          self.historyPingIterations > MAX_HISTORY_PINGS
        ) {
          self.historyPingIterations = 0
          self.historyStore.replace(newHistory)
          // Update current request
          for (let availableID of self.historyStore) {
            self.currentRequest.splice(
              self.currentRequest.map(datum => datum.id).indexOf(availableID.id),
              1,
            )
          }
        } else {
          self.historyPingIterations += 1
          debounce(self.pingFetchHistory, 2000)()
        }
      } catch (e) {}
    }),
    fetchMockDataset: flow(function* fetchDataset() {
      self.dataset = null
      self.fetchStatus = 'pending'
      try {
        const response = yield fetchData('mock_data.json')
        self.dataset = parseRawData(response)
        self.fetchStatus = 'done'
      } catch (error) {
        self.fetchStatus = 'error'
      }
    }),
    deleteFromHistory: flow(function*(id) {
      try {
        // remove: get /remove/<hash>. returns {status: "removed"|"error", error: null | <errore>}. error 429 TOO MANY REQUESTS
        const response = yield ky.post(`${DELETE_HISTORY_URL}`, { json: { id: id } }).json()
        debounce(self.setHistory, 200)()
      } catch (e) {}
    }),
    addCurrentRequest(id, timeRequest, label) {
      self.currentRequest.push({ id, timeRequest, label })
      self.pingFetchHistory()
    },
  }))
  .views(self => ({
    get dataUrl() {
      const { locationResult } = getRoot(self).ui
      return locationResult
    },
  }))

export const dataStore = DataStore.create({ fetchStatus: 'done' })
