import React from 'react'
import { inject, observer } from 'mobx-react'
import { keys, toLower } from 'lodash'
import {
  ROLES_MAP,
  LegendRole,
  LegendLink,
  LegendQuestionMark,
  LegendExclamationMark,
} from '../decorators/inscribedInSquare'

const LABELS_PER_ROW = 14
const TILES_PER_ROW = 9

@inject('global')
@observer
export default class Legend extends React.Component {
  render() {
    const { global, relevantToTile, oppositeToTile } = this.props
    const {
      oppositeWordsOccurrences,
      oppositeWords,
      relevantWordsLower,
      relevantWords,
    } = global.data.dataset

    let legendRelevant = {}
    keys(relevantToTile)
      .filter(word => !oppositeWordsOccurrences.includes(word))
      .forEach(word => {
        if (relevantToTile[word] !== undefined) {
          legendRelevant[relevantWords[relevantWordsLower.indexOf(word)]] = relevantToTile[word]
        }
      })

    let legendOpposite = {}
    keys(oppositeWords).forEach(word => {
      legendOpposite[word] = [
        { word, Tile: oppositeToTile[toLower(word)] },
        { word: oppositeWords[word], Tile: oppositeToTile[toLower(oppositeWords[word])] },
      ]
    })
    const showOpposite = keys(legendOpposite).length > 0

    // const width = global.ui.widthPage
    const width = 5000
    const labelWidth = width / (LABELS_PER_ROW + 2 / 3)
    const labelHeight = labelWidth * 1.1
    const tileSide = labelWidth * 0.6
    const titleHeight = labelHeight * 0.4
    const margin = labelWidth / 3
    const height = 7 * labelHeight + 2 * margin + titleHeight
    return (
      <svg
        id="legend"
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x={0} y={0} width={width} height={height} fill="white" />
        <g transform={`translate(${margin},${margin})`}>
          <Title x={0} y={titleHeight / 4} titleHeight={titleHeight}>
            Parole Chiave
          </Title>
          {keys(legendRelevant)
            .sort((a, b) => toLower(a).localeCompare(toLower(b)))
            .map((word, i) => {
              const Tile = legendRelevant[word]
              return (
                <g key={i}>
                  <Tile
                    x={(i % TILES_PER_ROW) * labelWidth}
                    y={Math.floor(i / TILES_PER_ROW) * labelHeight + titleHeight}
                    side={tileSide}
                    grid
                  />
                  <Caption
                    x={(i % TILES_PER_ROW) * labelWidth}
                    y={
                      Math.floor(i / TILES_PER_ROW) * labelHeight + labelHeight * 0.7 + titleHeight
                    }
                    fontSize={labelWidth / 10}
                  >
                    {word}
                  </Caption>
                </g>
              )
            })}
          {showOpposite && (
            <Title x={10 * labelWidth} y={titleHeight / 4} titleHeight={titleHeight}>
              Termini Opposti
            </Title>
          )}
          {keys(legendOpposite)
            .sort((a, b) => toLower(a).localeCompare(toLower(b)))
            .map((w, i) => {
              const data = legendOpposite[w]
              return data.map(({ word, Tile }, j) => (
                <g key={j}>
                  <Tile
                    x={(j + 10) * labelWidth}
                    y={i * labelHeight + titleHeight}
                    side={tileSide}
                    grid
                  />
                  <Caption
                    x={j * labelWidth + 10 * labelWidth}
                    y={i * labelHeight + labelHeight * 0.7 + titleHeight}
                    fontSize={labelWidth / 10}
                  >
                    {word}
                  </Caption>
                </g>
              ))
            })}
          {showOpposite && (
            <line
              x1={10 * labelWidth}
              y1={keys(legendOpposite).length * labelHeight + titleHeight}
              x2={14 * labelWidth}
              y2={keys(legendOpposite).length * labelHeight + titleHeight}
              stroke="black"
              strokeWidth={labelWidth / 70}
            />
          )}
          {/* Attributes */}
          <g
            transform={`translate(${10 * labelWidth},${keys(legendOpposite).length * labelHeight +
              (showOpposite ? titleHeight * 1.2 : 0)})`}
          >
            <Title x={0} y={titleHeight / 2} titleHeight={titleHeight}>
              Attributi
            </Title>
            {keys(ROLES_MAP).map((role, i) => (
              <g key={role}>
                <LegendRole
                  x={i * labelWidth}
                  y={titleHeight}
                  role={ROLES_MAP[role]}
                  side={tileSide}
                />
                <Caption
                  x={i * labelWidth}
                  y={labelHeight * 0.7 + titleHeight}
                  fontSize={labelWidth / 10}
                >
                  {role}
                </Caption>
              </g>
            ))}
            <LegendLink x={0} y={titleHeight + labelHeight} side={tileSide} />
            <Caption
              x={0}
              y={labelHeight * 0.7 + titleHeight + labelHeight}
              fontSize={labelWidth / 10}
            >
              <tspan>Se presenti</tspan>
              <tspan x={0} dy="1.2em">
                nella stessa frase
              </tspan>
            </Caption>
            <LegendQuestionMark x={labelWidth * 2} y={titleHeight + labelHeight} side={tileSide} />
            <Caption
              x={labelWidth * 2}
              y={labelHeight * 0.7 + titleHeight + labelHeight}
              fontSize={labelWidth / 10}
            >
              <tspan>Se in frase</tspan>
              <tspan x={labelWidth * 2} dy="1.2em">
                con ?
              </tspan>
            </Caption>
            <LegendExclamationMark
              x={labelWidth * 3}
              y={titleHeight + labelHeight}
              side={tileSide}
            />
            <Caption
              x={labelWidth * 3}
              y={labelHeight * 0.7 + titleHeight + labelHeight}
              fontSize={labelWidth / 10}
            >
              <tspan>Se in frase</tspan>
              <tspan x={labelWidth * 3} dy="1.2em">
                con !
              </tspan>
            </Caption>
          </g>
        </g>
      </svg>
    )
  }
}

const Title = ({ children, titleHeight, x, y }) => (
  <text
    x={x}
    y={y}
    fontSize={titleHeight / 2.5}
    alignmentBaseline="middle"
    fontFamily="sans-serif"
    style={{ fontWeight: 'bolder', letterSpacing: titleHeight / 50 }}
  >
    {children}
  </text>
)

const Caption = props => (
  <text
    {...props}
    style={{ fill: 'black' }}
    fontFamily="sans-serif"
    fontStyle="italic"
    alignmentBaseline="middle"
  >
    {props.children}
  </text>
)
