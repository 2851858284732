import React from 'react'
import { inject, observer } from 'mobx-react'

@inject('global')
@observer
export default class Picture extends React.Component {
  state = { mounted: false }
  componentDidMount() {
    this.setState({ mounted: true })
  }

  render() {
    const { wordToTile, dataToShow, global } = this.props
    const { linked } = global.data.dataset
    const { mounted } = this.state
    const tilesPerRow = Math.ceil((Math.sqrt(dataToShow.length) * 4) / 3)

    const width = 5000
    const side = width / (tilesPerRow + 2 / 3)
    const margin = side / 3
    const height = Math.ceil(dataToShow.length / tilesPerRow) * side + 2 * margin || width / 2
    return (
      <svg
        id="picture"
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x={0} y={0} width={width} height={height} fill="white" />
        <g transform={`translate(${margin},${margin})`}>
          <g id="g-grid" />
          <g id="g-linked" />
          <g id="g-tiles">
            {mounted &&
              dataToShow.map((datum, i) => {
                const Tile = wordToTile[datum.occurance]
                return (
                  <Tile
                    key={i}
                    x={(i % tilesPerRow) * side}
                    y={Math.floor(i / tilesPerRow) * side}
                    side={side}
                    role={datum.role}
                    exclamation={datum.exclamationMark}
                    question={datum.questionMark}
                    linked={linked.includes(datum.index)}
                  />
                )
              })}
          </g>
          <g id="g-role" />
          <g id="g-marks" />
        </g>
      </svg>
    )
  }
}
