import { types as t, flow, getRoot } from 'mobx-state-tree'
import { debounce, get, zip, zipObject, uniq, toLower, reduce } from 'lodash'
import ky from 'ky'
import FormData from 'form-data'
import dayjs from 'dayjs'

const ERRORS = new Set(['error', 'parse error'])
const POST_URL = `${process.env.SERVER_URL}/submit_task`
const ERROR_DELAY = 5000

function createOppositeWordsFile(words) {
  const validWords = words.filter(w => w.trim() !== '')
  const keys = validWords.filter((_, i) => i % 2 === 0)
  const values = validWords.filter((_, i) => i % 2 !== 0)
  const obj = zipObject(keys, values)
  return new window.File([JSON.stringify(obj)], 'opp_words.json')
}

const FALLBACK_WORDS = ['', '', '', '', '', '', '', '']

const FormStore = t
  .model('formStore', {
    textFile: t.frozen(),
    words: t.optional(t.array(t.string), FALLBACK_WORDS),
    label: '',
  })
  .actions(self => ({
    setTextFile(file) {
      if (file === undefined) self.textFile = null
      else {
        self.textFile = file
        if (file !== null && self.label.trim() === '') {
          self.setLabel(file.name)
        }
      }
    },
    setWord(index, word) {
      self.words[index] = word
    },
    setLabel(label) {
      self.label = label
    },
  }))
  .views(self => ({
    get fileOK() {
      return self.textFile !== null
    },
    get fileName() {
      return self.fileOK ? self.textFile.name : null
    },
    get formData() {
      const formData = new FormData()
      formData.set('payload', self.textFile)
      formData.set('opposite_words', createOppositeWordsFile(self.words))
      formData.set('label', self.label)
      return formData
    },
    get areDuplicate() {
      const { words } = self
      const cleanWords = words.map(w => toLower(w.trim()))
      const uniqs = uniq(cleanWords).filter(w => w !== '')
      const duplicates = uniqs.filter(w => cleanWords.filter(x => x === w).length > 1)
      const valids = cleanWords.map(w => !duplicates.includes(w))

      return valids
    },
    get areMissing() {
      const { words } = self
      const cleanWords = words.map(w => toLower(w.trim()))
      let valids = cleanWords.map((w, i) => {
        if (w !== '') return true
        return cleanWords[i + (i % 2 === 0 ? +1 : -1)] === ''
      })
      return valids
    },
    get areValid() {
      return zip(self.areDuplicate, self.areMissing).map(([a, b]) => a && b)
    },
    get isPostAllowed() {
      return self.textFile !== null && reduce(self.areValid, (a, b) => a && b, true)
    },
  }))

export const UIStore = t
  .model('uiStore', {
    widthPage: t.maybeNull(t.number),
    locationResult: t.maybeNull(t.string),
    postStatus: t.enumeration('state', ['pending', 'done', 'error', 'parse error']),
    formStore: t.optional(FormStore, { textFile: null }),
    uploadOpen: true,
    errorVisible: false,
  })
  .actions(self => ({
    computeDimension() {
      self.widthPage = window.innerWidth
      self.heightPage = window.innerHeight
    },
  }))
  .actions(self => ({
    afterCreate() {
      self.computeDimension()
      window.addEventListener('resize', debounce(self.computeDimension, 50))
    },
  }))
  .actions(self => ({
    postData: flow(function* postData() {
      const { formData } = self.formStore
      self.locationResult = null
      self.postStatus = 'pending'
      try {
        const response = yield ky.post(POST_URL, { body: formData }).json()
        self.locationResult = get(response, 'location', null)
        const status = get(response, 'status', 'error')

        if (ERRORS.has(status)) {
          self.errorVisible = true
          setTimeout(() => self.resetErrorVisibility(), ERROR_DELAY)
        }

        self.postStatus = status

        self.newDataFetched = false
        const { data } = getRoot(self)
        const id = self.locationResult.replace('/status/', '')
        if (data.historyStore.map(datum => datum.id).includes(id)) {
          const element = data.historyStore[data.historyStore.map(datum => datum.id).indexOf(id)]
          window.alert(`Esiste gia.\n\n${element.label}\n${element.time}\n${element.day}`)
        } else data.addCurrentRequest(id, dayjs().unix(), self.formStore.label)
      } catch (error) {
        self.postStatus = 'error'
      }
    }),
    toggleUploadOpen() {
      self.uploadOpen = !self.uploadOpen
    },
    resetErrorVisibility() {
      self.errorVisible = false
    },
  }))
  .views(self => ({
    get isPostNotAllowed() {
      return !self.formStore.isPostAllowed
    },
  }))

export const uiStore = UIStore.create({
  postStatus: 'pending',
})
