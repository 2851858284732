import React from 'react'
import { inscribedInSquare, ExclamationMark, QuestionMark } from '../decorators/inscribedInSquare'
import colors from './colors'

function createOpposite1(is) {
  @inscribedInSquare
  class Opposite1 extends React.Component {
    render() {
      const { x, y, side, exclamation, question } = this.props
      return (
        <>
          <rect
            x={x + side / 24}
            y={y + side / 24}
            width={(side * 11) / 24}
            height={side - side / 12}
            fill={is ? colors.grey : colors.magenta}
          />
          <line
            x1={x + side / 2}
            y1={y + side / 2}
            x2={x + side}
            y2={y + side / 2}
            stroke="black"
            strokeWidth={side / 48}
          />
          {exclamation && (
            <ExclamationMark x={x + side / 1.2} y={y + side / 1.1} {...{ side, exclamation }} />
          )}
          {question && (
            <QuestionMark x={x + side / 1.5} y={y + side / 1.1} {...{ side, question }} />
          )}
        </>
      )
    }
  }

  return Opposite1
}

function createOpposite2(is) {
  @inscribedInSquare
  class Opposite2 extends React.Component {
    render() {
      const { x, y, side, exclamation, question } = this.props
      return (
        <>
          <line x1={x} y1={y + side} x2={x + side} y2={y} stroke="black" strokeWidth={side / 48} />
          <path
            d={`
            M${x + side / 2}, ${y + side / 6}
            L${x + side / 1.2}, ${y + side / 2}
            L${x + side / 2}, ${y + side / 1.2}
            L${x + side / 6}, ${y + side / 2}
            z
          `}
            fill={is ? colors.lightgreen : colors.lightblue1}
          />
          {exclamation && (
            <ExclamationMark x={x + side / 1.2} y={y + side / 1.1} {...{ side, exclamation }} />
          )}
          {question && (
            <QuestionMark x={x + side / 1.5} y={y + side / 1.1} {...{ side, question }} />
          )}
        </>
      )
    }
  }
  return Opposite2
}

function createOpposite3(is) {
  @inscribedInSquare
  class Opposite3 extends React.Component {
    render() {
      const { x, y, side, question, exclamation } = this.props
      return (
        <>
          <path
            d={`
            M${x + side / 24}, ${y + side / 24}
            L${x + side - side / 24}, ${y + side - side / 24}
            L${x + side / 24}, ${y + side - side / 24}
            z
        `}
            fill={is ? colors.orange : colors.red}
          />
          {exclamation && (
            <ExclamationMark x={x + side / 1.2} y={y + side / 2} {...{ side, exclamation }} />
          )}
          {question && <QuestionMark x={x + side / 1.5} y={y + side / 2} {...{ side, question }} />}
        </>
      )
    }
  }
  return Opposite3
}

function createOpposite4(is) {
  @inscribedInSquare
  class Opposite4 extends React.Component {
    render() {
      const { x, y, side, question, exclamation } = this.props
      return (
        <>
          <path
            d={`
          M${x + side / 24}, ${y + side / 24}
          L${x + side - side / 24}, ${y + side - side / 24}
          L${x + side - side / 24}, ${y + side / 24}
            z
          `}
            fill={is ? colors.blue : colors.lightblue2}
          />
          {exclamation && (
            <ExclamationMark
              x={x + side / 1.2 - side / 24}
              y={y + side / 1.1}
              {...{ side, exclamation }}
            />
          )}
          {question && (
            <QuestionMark
              x={x + side / 1.5 - side / 24}
              y={y + side / 1.1}
              {...{ side, question }}
            />
          )}
        </>
      )
    }
  }
  return Opposite4
}

export const opposites = [createOpposite1, createOpposite2, createOpposite3, createOpposite4]
