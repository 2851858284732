import React from 'react'
import Dropzone from 'react-dropzone'
import classNames from 'classnames'
import { inject, observer } from 'mobx-react'

@inject('global')
@observer
export default class TextFileDropzone extends React.Component {
  onDrop = accetedFiles => this.props.global.ui.formStore.setTextFile(accetedFiles[0])

  removeFile = e => {
    this.props.global.ui.formStore.setTextFile(null)
    e.stopPropagation()
  }

  render() {
    const { fileOK, fileName } = this.props.global.ui.formStore
    return (
      <Dropzone onDrop={this.onDrop} accept=".txt" multiple={false}>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div
            className={classNames(' flex items-center bb outline-transparent dropzone ', {
              'dropzone-ok': fileOK && !isDragActive,
              dragging: isDragActive,
            })}
            {...getRootProps()}
          >
            <input {...getInputProps()} className="outline-transparent" />
            <div className="dropzone-content">
              {(isDragActive && 'Lascia per caricare il file') ||
                (!fileOK && 'Trascina o clicca per caricare un file') || (
                  <>
                    <div className="file-caption b">File caricato</div>
                    <div className="file-name">{fileName}</div>
                  </>
                )}
            </div>
            {fileOK && (
              <div
                className="dismiss-file br-100 tc b gray bg-transparent ml3"
                onClick={this.removeFile}
              >
                &times;
              </div>
            )}
          </div>
        )}
      </Dropzone>
    )
  }
}
