import React from 'react'
import { Provider } from 'mobx-react'
import { global } from '../stores/global'
import View from './View'
import { Upload } from './Upload'
import { Error } from './Error'

export default class App extends React.Component {
  render() {
    return (
      <Provider global={global}>
        <>
          <Upload />
          <View />
          <Error />
        </>
      </Provider>
    )
  }
}
