export default {
  purple: 'rgb(120,85,201)',
  grey: 'rgb(142,142,142)',
  magenta: 'rgb(206,19,80)',
  lightgreen: 'rgb(221,249,176)',
  lightblue1: 'rgb(197,231,225)',
  orange: 'rgb(250,157,61)',
  red: 'rgb(227,73,76)',
  blue: 'rgb(25,20,89)',
  lightblue2: 'rgb(161,204,255)',
}
